import { Environment } from '../enviroment';

export const env: Environment = {
   name: 'PRODUCTION',
   firebase: {
      apiKey: 'AIzaSyAzD64olUbX3Tu4jO74rW7ZFVTAbjVxMl0',
      authDomain: 'cms.gloww.com',
      projectId: 'foreground-production',
      storageBucket: 'foreground-production.appspot.com',
      messagingSenderId: '912447064598',
      appId: '1:912447064598:web:11120e61fe09b66e1e8337',
      measurementId: 'G-K93BYHC9L0',
      functionsDomain: 'us-central1-foreground-production.cloudfunctions.net',
      functionsRegion: 'us-central1',
      assetsBucket: 'foreground-production-assets-storage',
   },
   appDomain: 'app.gloww.com',
};
